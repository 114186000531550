import clsx from 'clsx';
import Button from 'epn-ui/umd/dist/components/Button';

// Styles
import styles from './WhiteButton.module.scss';

export interface IWhiteButtonProps {
    block?: boolean;
    size?: 'large' | 'small' | 'default' | 'medium';
    transparent?: boolean;
    className?: string;
    href: string;
}

const WhiteButton: React.FC<IWhiteButtonProps> = ({
    block = true,
    size = 'large',
    transparent = true,
    href,
    className,
    children,
}) => {
    return (
        <Button.Beta
            block={block}
            size={size}
            className={clsx(className, {
                [styles.WhiteButtonT]: transparent,
                [styles.WhiteButtonNt]: !transparent,
            })}
            href={href}
        >
            {children}
        </Button.Beta>
    );
};

export default WhiteButton;

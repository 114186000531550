import Button from 'epn-ui/umd/dist/components/Button';
import useI18n from '@i18n';

import styles from './RegisterButton.module.scss';

interface IRegisterButtonProps {
    handleRegistration: () => void;
    widgetIsLoading: boolean;
    isAuth: boolean;
}

const RegisterButton: React.FC<IRegisterButtonProps> = ({
    widgetIsLoading,
    handleRegistration,
    isAuth,
}) => {
    const { t, locale } = useI18n();
    const text = isAuth ? t('View all partners') : t('Registration-Sign-up');

    const handleClick = () => {
        if (isAuth) {
            window.open(`/${locale}/offers`);
            return;
        }
        handleRegistration();
    };

    const btnIsDisabled = !isAuth && widgetIsLoading;

    return (
        <Button.Beta
            className={styles.btn}
            size="large"
            type="primary"
            onClick={handleClick}
            disabled={btnIsDisabled}
        >
            {text}
        </Button.Beta>
    );
};

export default RegisterButton;

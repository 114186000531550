import { useState } from 'react';
import Collapse from 'epn-ui/umd/dist/components/Collapse';
import useI18n, { LocaleTypes } from '@i18n';
import { companyName } from '@config/globalDomain';
import SectionTitle from '../Base/SectionTitle';

import styles from './Instruments.module.scss';

import INSTRUMENT_1_RU from './images/ru/instrument_1.png';
import INSTRUMENT_2_RU from './images/ru/instrument_2.png';
import INSTRUMENT_3_RU from './images/ru/instrument_3.png';
import INSTRUMENT_4_RU from './images/ru/instrument_4.png';
import INSTRUMENT_5_RU from './images/ru/instrument_5.png';
import INSTRUMENT_6_RU from './images/ru/instrument_6.png';
import INSTRUMENT_7_RU from './images/ru/instrument_7.png';

import INSTRUMENT_1_EN from './images/en/instrument_1.png';
import INSTRUMENT_2_EN from './images/en/instrument_2.png';
import INSTRUMENT_3_EN from './images/en/instrument_3.png';
import INSTRUMENT_4_EN from './images/en/instrument_4.png';
import INSTRUMENT_5_EN from './images/en/instrument_5.png';
import INSTRUMENT_6_EN from './images/en/instrument_6.png';
import INSTRUMENT_7_EN from './images/en/instrument_7.png';

const IMAGES = {
    [LocaleTypes.ru]: {
        instrument_1: INSTRUMENT_1_RU,
        instrument_2: INSTRUMENT_2_RU,
        instrument_3: INSTRUMENT_3_RU,
        instrument_4: INSTRUMENT_4_RU,
        instrument_5: INSTRUMENT_5_RU,
        instrument_6: INSTRUMENT_6_RU,
        instrument_7: INSTRUMENT_7_RU,
    },
    [LocaleTypes.en]: {
        instrument_1: INSTRUMENT_1_EN,
        instrument_2: INSTRUMENT_2_EN,
        instrument_3: INSTRUMENT_3_EN,
        instrument_4: INSTRUMENT_4_EN,
        instrument_5: INSTRUMENT_5_EN,
        instrument_6: INSTRUMENT_6_EN,
        instrument_7: INSTRUMENT_7_EN,
    },
};

const DEFAULT_ITEM = 0;

const BlockInstruments: React.FC = () => {
    const [item, setItem] = useState(null);

    const { t, locale } = useI18n();

    const localizedImages = IMAGES[locale];

    const INSTRUMENTS = [
        {
            title: t('Link Shortener'),
            body: t(
                'Our own link shorteners for maximum convenience when working with social networks, messengers, and websites',
            ),
            image: localizedImages.instrument_1,
            key: 0,
        },
        // {
        //     title: t('Promo Codes and Coupons'),
        //     body: t(
        //         'Receive discount coupons and share them with your audience. Great for social networks, aggregator websites, and coupon platforms',
        //     ),
        //     image: localizedImages.instrument_2,
        //     key: 1,
        // },
        {
            title: t('Product feeds'),
            body: t('If you are a dropshipper, affiliate store owner or price comparison service'),
            image: localizedImages.instrument_3,
            key: 1,
        },
        {
            title: t('Browser extension'),
            body: t('Get affiliate links right on the advertisers site'),
            image: localizedImages.instrument_4,
            key: 2,
        },
        {
            title: t('Telegram bot'),
            body: t(
                'Create affiliate links from any device and connect your employees to create affiliate links',
            ),
            image: localizedImages.instrument_5,
            key: 3,
        },
        {
            title: t('Referral program'),
            body: t('Get 5% of ePN earnings from referred webmasters', { companyName }),
            image: localizedImages.instrument_6,
            key: 4,
        },
        {
            title: t('API'),
            body: t('Every ePN partner can access the API for remote access to the systems tools', {
                companyName,
            }),
            image: localizedImages.instrument_7,
            key: 5,
        },
    ];

    const onChange = (key: string) => {
        setItem(INSTRUMENTS[key]);
    };

    return (
        <section className={styles.landingContainer}>
            <div className={styles.instruments}>
                <SectionTitle>{t('Tools')}</SectionTitle>
                <div className={styles.instruments_wrapper}>
                    <div className={styles.list}>
                        <Collapse
                            accordion
                            defaultActiveKey={DEFAULT_ITEM}
                            theme="ghost"
                            items={INSTRUMENTS}
                            onChange={onChange}
                        />
                    </div>
                    <div className={styles.image}>
                        <img src={item?.image || INSTRUMENTS[0].image} alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlockInstruments;

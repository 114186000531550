import clsx from 'clsx';
import styles from './ForWhomBlock.module.scss';

interface IForWhomBlockProps {
    imgSrc: string;
    text: string;
    classname?: string;
}

const ForWhomBlock: React.FC<IForWhomBlockProps> = ({ imgSrc, text, classname }) => (
    <div className={clsx(styles.forWhomBlock, classname)}>
        <div className={styles.forWhomBlock_img}>
            <img src={imgSrc} alt={text} />
        </div>
        <div className={styles.forWhomBlock_text}>
            <p>{text}</p>
        </div>
    </div>
);

export default ForWhomBlock;

import Button from 'epn-ui/umd/dist/components/Button';

// Styles
import styles from './RedButton.module.scss';

export interface IRedButtonProps {
    href: string;
}

const RedButton: React.FC<IRedButtonProps> = ({ href, children }) => {
    return (
        <div className={styles.wrapper}>
            <Button.Beta className={styles.RedButton} href={href}>
                {children}
            </Button.Beta>
        </div>
    );
};

export default RedButton;

import Cookies from 'js-cookie';

export interface IGetReferralParamsResult {
    clickId?: string;
    inviter?: string;
    utmMedium?: string;
}

/**
 * Вытаскивает реферальные параметры из урла.
 * Если таких нет, то возвращает null
 */
const getReferralParams = (): IGetReferralParamsResult | null => {
    if (typeof window === 'undefined') return null;

    const { searchParams } = new URL(window.location.href);
    const clickId = searchParams.get('click_id');
    const inviter = searchParams.get('inviter');
    const queryUtmMedium = searchParams.get('utm_medium');
    const cookieUtmMedium = Cookies.get('utm_medium');
    const hasQueryUtmMediumCpc = queryUtmMedium && queryUtmMedium.search(/cpc/gi) !== -1;
    const hasCookieUtmMedium = cookieUtmMedium && cookieUtmMedium.search(/cpc/gi) !== -1;

    if (inviter) return { clickId, inviter };

    // Для отображения виджета если utm_medium содержит cpc
    if (hasQueryUtmMediumCpc || hasCookieUtmMedium) {
        return { utmMedium: queryUtmMedium || cookieUtmMedium };
    }

    return null;
};

export default getReferralParams;

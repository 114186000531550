import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/pro-regular-svg-icons/faChartLine';
import { faUserChart } from '@fortawesome/pro-regular-svg-icons/faUserChart';
import { faShieldCheck } from '@fortawesome/pro-regular-svg-icons/faShieldCheck';
import { faLifeRing } from '@fortawesome/pro-regular-svg-icons/faLifeRing';

// Helpers
import useI18n from '@i18n';

// Styles
import styles from './Advantages.module.scss';

const Advantages: React.FC = () => {
    const { t } = useI18n();

    return (
        <section className={styles.Advantages}>
            <div className={styles.landingContainer}>
                <h2 className={clsx([styles.blockTitle, styles.Advantages__title])}>
                    {t('Our Advantages')}
                </h2>

                <div className={styles.Advantages__items}>
                    <div className={styles.Advantages__card}>
                        <div className={styles.Advantages__cardIcon}>
                            <FontAwesomeIcon icon={faChartLine} />
                        </div>
                        <div className={styles.Advantages__cardTitle}>
                            {t('Detailed statistics')}
                        </div>
                        <div className={styles.Advantages__cardContent}>
                            {t(
                                'Real-time statistics for all ads on clicks, orders, and sales, available for viewing by country, operating system, creatives, and subid.',
                            )}
                        </div>
                    </div>

                    <div className={styles.Advantages__card}>
                        <div className={styles.Advantages__cardIcon}>
                            <FontAwesomeIcon icon={faUserChart} />
                        </div>
                        <div className={styles.Advantages__cardTitle}>{t('User Tracking')}</div>
                        <div className={styles.Advantages__cardContent}>
                            {t(
                                'Discover which ad campaign led to a product purchase, when it happened, and the commission it brings you. Optimize your campaign and maximize profits!',
                            )}
                        </div>
                    </div>

                    <div className={styles.Advantages__card}>
                        <div className={styles.Advantages__cardIcon}>
                            <FontAwesomeIcon icon={faShieldCheck} />
                        </div>
                        <div className={styles.Advantages__cardTitle}>
                            {t('Traffic protection system')}
                        </div>
                        <div className={styles.Advantages__cardContent}>
                            {t(
                                'A unique security system by Source Tech that safeguards us and our partners from potential fraud, external interference, and manipulations.',
                            )}
                        </div>
                    </div>

                    <div className={styles.Advantages__card}>
                        <div className={styles.Advantages__cardIcon}>
                            <FontAwesomeIcon icon={faLifeRing} />
                        </div>
                        <div className={styles.Advantages__cardTitle}>{t('Technical Support')}</div>
                        <div className={styles.Advantages__cardContent}>
                            {t(
                                'Our tech support offers a personalized approach for each webmaster, gladly answering all questions and helping to resolve issues.',
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Advantages;

import clsx from 'clsx';

// Helpers
import useI18n from '@i18n';

// Components
import RedButton from '../RedButton';

// Styles
import styles from './Steps.module.scss';

const Steps: React.FC = () => {
    const { t, locale } = useI18n();
    const formLink = `/${locale}/cpa/apply`;

    return (
        <section className={styles.Steps}>
            <div className={styles.landingContainer}>
                <h2 className={styles.blockTitle}>{t('Make 3 Easy Steps')}</h2>

                <div className={styles.Steps__items}>
                    <div className={clsx([styles.Steps__card, styles.Steps__cardFirst])}>
                        <div className={styles.Steps__cardTitle}>{t('Register')}</div>
                    </div>

                    <div className={clsx([styles.Steps__card, styles.Steps__cardSecond])}>
                        <div className={styles.Steps__cardTitle}>{t('Direct traffic')}</div>
                    </div>

                    <div className={clsx([styles.Steps__card, styles.Steps__cardThird])}>
                        <div className={styles.Steps__cardTitle}>{t('Earn')}</div>
                    </div>
                </div>

                <div className={styles.Steps__actions}>
                    <RedButton href={formLink}>
                        <span>{t('Join us')}</span>
                    </RedButton>
                </div>
            </div>
        </section>
    );
};

export default Steps;

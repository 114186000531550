import useI18n, { LocaleTypes } from '@i18n';
import RegisterButton from '../Base/RegisterButton';

import styles from './Header.module.scss';
import ImgRu from './header-img-ru.jpg';
import ImgEn from './header-img-en.jpg';

interface IBlockHeaderProps {
    handleRegistration: () => void;
    widgetIsLoading: boolean;
    isAuth: boolean;
}

const IMAGE = {
    [LocaleTypes.ru]: ImgRu,
    [LocaleTypes.en]: ImgEn,
};

const BlockHeader: React.FC<IBlockHeaderProps> = ({
    handleRegistration,
    widgetIsLoading,
    isAuth,
}) => {
    const { t, locale } = useI18n();
    return (
        <div className={styles.header}>
            <div className={styles.header_wrapper}>
                <div className={styles.header_title}>
                    <h2>
                        {t('Monetize your traffic with a')}
                        <span className={styles.red}> {t('major')} </span>
                        {t('CPA network')}
                    </h2>
                    <div className={styles.header_button}>
                        <RegisterButton
                            handleRegistration={handleRegistration}
                            widgetIsLoading={widgetIsLoading}
                            isAuth={isAuth}
                        />
                    </div>
                </div>
                <div className={styles.header_image}>
                    <img src={IMAGE[locale]} alt="Epn" />
                </div>
            </div>
        </div>
    );
};

export default BlockHeader;

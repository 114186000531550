import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/pro-solid-svg-icons/faBullhorn';
import { faUserTie } from '@fortawesome/pro-solid-svg-icons/faUserTie';
import { faShoppingBag } from '@fortawesome/pro-solid-svg-icons/faShoppingBag';
import { faHandPointer } from '@fortawesome/pro-regular-svg-icons/faHandPointer';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons/faDollarSign';

// Helpers
import useI18n from '@i18n';

// Graphics
import EpnAffiliateLogo from '../../../../../assets/images/affiliate/webmaster/epn-affiliate-logo.png';

// Styles
import styles from './HowItWork.module.scss';

const HowItWork: React.FC = () => {
    const { t } = useI18n();

    return (
        <section className={styles.HowItWork}>
            <div className={styles.landingContainer}>
                <h2 className={styles.blockTitle}>
                    {t('How does e-Commerce Partners Network work?')}
                </h2>
                <div className={styles.HowItWork__items}>
                    <div className={styles.HowItWork__card}>
                        <div className={styles.HowItWork__cardTitle}>{t('Channels')}</div>
                        <ul
                            className={clsx([
                                styles.HowItWork__cardContent,
                                styles.HowItWork__cardContentList,
                            ])}
                        >
                            <li>• {t('Web site')}</li>
                            <li>• {t('SMM Marketing')}</li>
                            <li>• {t('Email Marketing')}</li>
                            <li>• {t('Contextual Advertising (PPC)')}</li>
                            <li>• {t('Banner Advertising')}</li>
                            <li>• {t('Comparison Services')}</li>
                            <li>• {t('Promo Codes and Discounts')}</li>
                            <li>• {t('Cashback and Bonus Sites')}</li>
                            <li>• {t('Retargeting')}</li>
                        </ul>
                        <div className={styles.HowItWork__cardIcon}>
                            <FontAwesomeIcon icon={faBullhorn} />
                        </div>

                        <div
                            className={clsx([
                                styles.HowItWork__scheme,
                                styles.HowItWork__chanelRight,
                            ])}
                        >
                            <FontAwesomeIcon icon={faArrowRight} />
                        </div>
                    </div>

                    <div className={styles.HowItWork__card}>
                        <div className={styles.HowItWork__cardTitle}>{t('Webmaster')}</div>
                        <div className={styles.HowItWork__cardContent}>
                            {t(
                                "Attracts customers to the advertiser's site using their platforms and tools. If a customer makes a purchase, the webmaster receives a reward.",
                            )}
                        </div>

                        <div className={styles.HowItWork__cardIcon}>
                            <FontAwesomeIcon icon={faUserTie} />
                        </div>

                        <div
                            className={clsx([styles.HowItWork__scheme, styles.HowItWork__wmDollar])}
                        >
                            <FontAwesomeIcon icon={faDollarSign} />
                        </div>

                        <div className={clsx([styles.HowItWork__scheme, styles.HowItWork__wmLeft])}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>

                        <div
                            className={clsx([styles.HowItWork__scheme, styles.HowItWork__wmRight])}
                        >
                            <FontAwesomeIcon icon={faArrowRight} />
                        </div>
                    </div>

                    <div className={styles.HowItWork__card}>
                        <div className={styles.HowItWork__cardTitle}>
                            {t('e-Commerce Partners Network')}
                        </div>
                        <div className={styles.HowItWork__cardContent}>
                            {t(
                                "Provides its platform, attracts webmasters to the advertiser's affiliate program, monitors traffic quality, and pays rewards.",
                            )}
                        </div>
                        <div className={styles.HowItWork__cardIcon} style={{ bottom: 0 }}>
                            <img src={EpnAffiliateLogo} alt="" />
                        </div>

                        <div
                            className={clsx([
                                styles.HowItWork__scheme,
                                styles.HowItWork__affiliateDollar,
                            ])}
                        >
                            <FontAwesomeIcon icon={faDollarSign} />
                        </div>

                        <div
                            className={clsx([
                                styles.HowItWork__scheme,
                                styles.HowItWork__affiliateLeft,
                            ])}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>

                        <div
                            className={clsx([
                                styles.HowItWork__scheme,
                                styles.HowItWork__affiliateRight,
                            ])}
                        >
                            <FontAwesomeIcon icon={faArrowRight} />
                        </div>
                    </div>

                    <div className={styles.HowItWork__card}>
                        <div className={styles.HowItWork__cardTitle}>{t('Advertiser')}</div>
                        <div className={styles.HowItWork__cardContent}>
                            {t('Wants to increase sales and launches an affiliate program.')}
                        </div>
                        <div className={styles.HowItWork__cardIcon}>
                            <FontAwesomeIcon icon={faShoppingBag} />
                        </div>
                    </div>
                </div>
                <div className={styles.HowItWork__tip}>
                    <FontAwesomeIcon icon={faHandPointer} className={styles.HowItWork__tipIcon} />
                    <span className={styles.HowItWork__tipText}>
                        {t('Scroll right to view the whole scheme')}
                    </span>
                </div>
            </div>
        </section>
    );
};

export default HowItWork;

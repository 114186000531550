import BaseCollapseWithGradient from '@components/base/BaseCollapseWithGradient';

// Helpers
import useI18n from '@i18n';

// Components
import TextByLocale from './TextByLocale';

// Styles
import styles from './BlockSeo.module.scss';

const Seo: React.FC = () => {
    const { t } = useI18n();

    return (
        <section className={styles.BlockSeo}>
            <div className={styles.landingContainer}>
                <BaseCollapseWithGradient>
                    <h2 className="mb-5 font-medium text-h2 text-blueGray-600">
                        {t('The best affiliates for webmasters')}
                    </h2>
                    <TextByLocale />
                </BaseCollapseWithGradient>
            </div>
        </section>
    );
};

export default Seo;

import React from 'react';
import clsx from 'clsx';
import useI18n, { LocaleTypes } from '@i18n';

import AdvatageBlock from './AdvantageBlock/AdvantageBlock';
import ContainerWithLayout from '../Base/ContainerWithLayout';
import SectionTitle from '../Base/SectionTitle';

import styles from './Advantages.module.scss';

import advantage1Img from './img/advantage1.svg';
import advantage3Img from './img/advantage3.svg';
import advantage4Img from './img/advantage4.svg';

const BlockAdvantages: React.FC = () => {
    const { t, locale } = useI18n();
    const cardWithShortHeader = locale === LocaleTypes.en;

    return (
        <section className={styles.advantages}>
            <ContainerWithLayout colorName="red">
                <div className={clsx(styles.landingContainer, styles.advantages_content)}>
                    <div className={styles.advantages_content_header}>
                        <SectionTitle classname={styles.advantages_content_title} bigger>
                            {t('Benefits of working with us')}
                        </SectionTitle>
                    </div>
                    <div className={styles.advantages_content_list}>
                        <AdvatageBlock
                            imgSrc={advantage1Img}
                            title={t('Detailed statistics')}
                            text={t(
                                'Statistics of all advertising campaigns including clicks, orders, and sales are available in real-time for viewing by country, operating system, creatives, and subid',
                            )}
                        />
                        <AdvatageBlock
                            cardWithShortHeader={cardWithShortHeader}
                            imgSrc={advantage3Img}
                            title={t('Personal manager')}
                            text={t(
                                'We are interested in helping your revenue grow, so we are always ready to quickly help you solve any questions',
                            )}
                        />
                        <AdvatageBlock
                            cardWithShortHeader={cardWithShortHeader}
                            imgSrc={advantage4Img}
                            title={t('Easy start')}
                            text={t(
                                'Over 200 offers and worldwide geos, exclusive offers, and special conditions for verified webmasters',
                            )}
                        />
                    </div>
                </div>
            </ContainerWithLayout>
        </section>
    );
};

export default BlockAdvantages;

import Head from 'next/head';

// Components
import Header from '@components/pages/home/epncpa/Header';
import HowItWork from '@components/pages/home/epncpa/BlockHowItWork';
// import BlockOurPartners from '@components/pages/affiliate/BlockOurPartners';
import Advantages from '@components/pages/home/epncpa/BlockAdvantages';
import Tools from '@components/pages/home/epncpa/BlockTools';
// import Payouts from '@components/pages/home/epncpa/BlockPayouts';
import Steps from '@components/pages/home/epncpa/BlockSteps';
import BlockSeo from '@components/pages/home/epncpa/BlockSeo';
import BlockBenefits from '@components/pages/home/epncpa/BlockBenefits';

// Helpers
import useI18n from '@i18n';
import { companyName } from '@config/globalDomain';

const WebmasterPage: React.FC = () => {
    const { t } = useI18n();

    return (
        <>
            <Head>
                <title>{t('ePN Affiliate - Traffic monetization', { companyName })}</title>
                <meta
                    name="description"
                    content={t(
                        'We will help you monetize your traffic, we will offer options for any audience',
                    )}
                />
            </Head>
            <Header />
            <HowItWork />
            {/* <BlockOurPartners /> */}
            <Advantages />
            <Tools />
            {/* <Payouts /> */}
            <BlockBenefits />
            <Steps />
            <BlockSeo />
        </>
    );
};

export default WebmasterPage;

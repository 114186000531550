import styles from './HowStartWorkBlock.module.scss';

interface IHowStartWorkBlockProps {
    imgSrc: string;
    title: string;
    text: string;
}

const HowStartWorkBlock: React.FC<IHowStartWorkBlockProps> = ({ imgSrc, title, text }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapper_img}>
                <img src={imgSrc} alt={title} />
            </div>
            <div className={styles.wrapper_text}>
                <h3>{title}</h3>
                <p>{text}</p>
            </div>
            <div className={styles.wrapper_layout} />
        </div>
    );
};

export default HowStartWorkBlock;

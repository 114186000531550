import useI18n from '@i18n';

import styles from './BlockBenefits.module.scss';

// Icons
import AffiliateMarketersIcon from './img/affiliate-marketers.svg';
import BeginnersBlogersIcon from './img/beginners-blogers.svg';
import CashbackServiceIcon from './img/cashback-service.svg';
import SocialsMediaIcom from './img/socials-media.svg';
import WebsiteOwnersIcon from './img/website-owners.svg';

const BlockBenefits: React.FC = () => {
    const { t } = useI18n();
    return (
        <section className={styles.benefits}>
            <div className={styles.landingContainer}>
                <h2 className={styles.blockTitle}>{t('Who is this for?')}</h2>
                <div className={styles.wrapper}>
                    <div className={styles.benefitsItem}>
                        <div className={styles.benefitsItem__icon}>
                            <img src={WebsiteOwnersIcon} alt="Website owners" />
                        </div>
                        <div className={styles.benefitsItem__text}>
                            <p>Website owners, price comparison services, affiliate stores</p>
                        </div>
                    </div>
                    <div className={styles.benefitsItem}>
                        <div className={styles.benefitsItem__icon}>
                            <img src={AffiliateMarketersIcon} alt="Affiliate marketers" />
                        </div>
                        <div className={styles.benefitsItem__text}>
                            <p>
                                Affiliate marketers who work with PPC (pay-per-click) and social
                                media advertising
                            </p>
                        </div>
                    </div>
                    <div className={styles.benefitsItem}>
                        <div className={styles.benefitsItem__icon}>
                            <img src={BeginnersBlogersIcon} alt="Beginner and experienced" />
                        </div>
                        <div className={styles.benefitsItem__text}>
                            <p>Beginner and experienced bloggers and video bloggers</p>
                        </div>
                    </div>
                    <div className={styles.benefitsItem}>
                        <div className={styles.benefitsItem__icon}>
                            <img src={CashbackServiceIcon} alt="Cashback services" />
                        </div>
                        <div className={styles.benefitsItem__text}>
                            <p>Cashback services and loyalty programs</p>
                        </div>
                    </div>
                    <div className={styles.benefitsItem}>
                        <div className={styles.benefitsItem__icon}>
                            <img src={SocialsMediaIcom} alt="Social media group" />
                        </div>
                        <div className={styles.benefitsItem__text}>
                            <p>Social media group users and owners, messaging channel owners</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlockBenefits;

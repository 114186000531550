import clsx from 'clsx';
import useI18n from '@i18n';

import HowStartWorkBlock from './HowStartWorkBlock';
import SectionTitle from '../Base/SectionTitle';
import ContainerWithLayout from '../Base/ContainerWithLayout';
import RegisterButton from '../Base/RegisterButton';

import styles from './HowStartWork.module.scss';

import step1Img from './img/step1.svg';
import step2Img from './img/step2.svg';
import step3Img from './img/step3.svg';
import step4Img from './img/step4.svg';

interface IHowStartWorkProps {
    handleRegistration: () => void;
    widgetIsLoading: boolean;
    isAuth: boolean;
}

const HowStartWork: React.FC<IHowStartWorkProps> = ({
    handleRegistration,
    widgetIsLoading,
    isAuth,
}) => {
    const { t } = useI18n();
    return (
        <section className={styles.howStartWork}>
            <ContainerWithLayout colorName="grey">
                <div className={clsx(styles.landingContainer, styles.howStartWork_content)}>
                    <div className={styles.howStartWork_header}>
                        <SectionTitle classname={styles.howStartWork_title} bigger>
                            {t('How to get started?')}
                        </SectionTitle>
                        <div className={styles.howStartWork_buttonWrapper}>
                            <RegisterButton
                                handleRegistration={handleRegistration}
                                widgetIsLoading={widgetIsLoading}
                                isAuth={isAuth}
                            />
                        </div>
                    </div>
                    <div className={styles.howStartWork_content_list}>
                        <HowStartWorkBlock
                            imgSrc={step1Img}
                            title={t('Sign up')}
                            text={t('After registering you will get access to 200+ offers')}
                        />
                        <HowStartWorkBlock
                            imgSrc={step2Img}
                            title={t('Choose an offer')}
                            text={t(
                                'Connect the platform, site or social network from which you will advertise',
                            )}
                        />
                        <HowStartWorkBlock
                            imgSrc={step3Img}
                            title={t('Launch traffic')}
                            text={t('Drive advertisers traffic that will perform targeted actions')}
                        />
                        <HowStartWorkBlock
                            imgSrc={step4Img}
                            title={t('Earn')}
                            text={t(
                                'Get rewards for your users actions and withdraw your earnings in any convenient way',
                            )}
                        />
                    </div>
                </div>
            </ContainerWithLayout>
        </section>
    );
};

export default HowStartWork;

// Helpers
import useI18n from '@i18n';

// Graphics
import ToolsImage1 from '../../../../../assets/images/affiliate/webmaster/tools-1.png';
import ToolsImage2 from '../../../../../assets/images/affiliate/webmaster/tools-2.png';
import ToolsImage3 from '../../../../../assets/images/affiliate/webmaster/tools-3.png';
import ToolsImage4 from '../../../../../assets/images/affiliate/webmaster/tools-4.png';

// Styles
import styles from './Tools.module.scss';

const Tools: React.FC = () => {
    const { t } = useI18n();

    return (
        <section className={styles.Tools}>
            <div className={styles.landingContainer}>
                <h2 className={styles.blockTitle}>{t('wm_land_Tools')}</h2>

                <div className={styles.Tools__items}>
                    <div className={styles.Tools__card}>
                        <div className={styles.Tools__cardIcon}>
                            <img src={ToolsImage1} alt="" />
                        </div>
                        <div className={styles.Tools__cardTitle}>
                            {t('Promo Codes and Coupons')}
                        </div>
                        <div className={styles.Tools__cardContent}>
                            {t(
                                'Promo codes are marketing tools designed to attract customers to online stores and boost their loyalty. They can offer discounts or other bonuses, like free shipping.',
                            )}
                        </div>
                    </div>

                    <div className={styles.Tools__card}>
                        <div className={styles.Tools__cardIcon}>
                            <img src={ToolsImage2} alt="" />
                        </div>
                        <div className={styles.Tools__cardTitle}>{t('Product Feeds')}</div>
                        <div className={styles.Tools__cardContent}>
                            {t(
                                'Tools that enable you to quickly set up your own site using product information obtained through our API.',
                            )}
                        </div>
                    </div>

                    <div className={styles.Tools__card}>
                        <div className={styles.Tools__cardIcon}>
                            <img src={ToolsImage3} alt="" />
                        </div>
                        <div className={styles.Tools__cardTitle}>{t('Link Shortener')}</div>
                        <div className={styles.Tools__cardContent}>
                            {t(
                                'Custom link shorteners for optimal convenience when working with social networks, messengers, and websites.',
                            )}
                        </div>
                    </div>

                    <div className={styles.Tools__card}>
                        <div className={styles.Tools__cardIcon}>
                            <img src={ToolsImage4} alt="" />
                        </div>
                        <div className={styles.Tools__cardTitle}>{t('wm_land_API')}</div>
                        <div className={styles.Tools__cardContent}>
                            {t(
                                'Each partner of the e-Commerce Partners Network can access the API for remote use of system tools.',
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Tools;

import clsx from 'clsx';
import useI18n from '@i18n';

import ForWhomBlock from './ForWhomBlock';
import ContainerWithLayout from '../Base/ContainerWithLayout';
import SectionTitle from '../Base/SectionTitle';

import webmastersImg from './img/webmasters.png';
import arbitrageImg from './img/arbitrage.png';
import blogersImg from './img/blogers.png';
import cashbackImg from './img/cashback.png';
import messengersImg from './img/messengers.png';

import styles from './ForWhom.module.scss';

const BlockForWhom: React.FC = () => {
    const { t } = useI18n();
    return (
        <section className={styles.forWhom}>
            <ContainerWithLayout colorName="grey">
                <div className={clsx(styles.landingContainer, styles.forWhom_content)}>
                    <div className={styles.forWhom_header}>
                        <SectionTitle classname={styles.forWhom_header_title} bigger>
                            {t('Who is this for?')}
                        </SectionTitle>
                        <ForWhomBlock
                            classname={styles.forWhom_header_card}
                            imgSrc={webmastersImg}
                            text={t(
                                'Owners of websites, price comparison services, affiliate stores',
                            )}
                        />
                    </div>
                    <div className={styles.forWhom_list}>
                        <ForWhomBlock
                            imgSrc={webmastersImg}
                            text={t(
                                'Owners of websites, price comparison services, affiliate stores',
                            )}
                        />
                        <ForWhomBlock
                            imgSrc={arbitrageImg}
                            text={t(
                                'Affiliate marketers who work with PPC (pay-per-click) and social media advertising',
                            )}
                        />
                        <ForWhomBlock
                            imgSrc={blogersImg}
                            text={t('Beginners and experienced bloggers and video bloggers')}
                        />
                        <ForWhomBlock
                            imgSrc={cashbackImg}
                            text={t('Cashback services and loyalty programs')}
                        />
                        <ForWhomBlock
                            imgSrc={messengersImg}
                            text={t(
                                'Users and owners of social media groups and channels in messaging apps',
                            )}
                        />
                    </div>
                </div>
            </ContainerWithLayout>
        </section>
    );
};

export default BlockForWhom;

// Helpers
import useI18n, { LocaleTypes } from '@i18n';

const TextByLocale: React.FC = () => {
    const { locale } = useI18n();

    if (locale === LocaleTypes.ru) {
        return (
            <div className="text-blueGray-800">
                <div className="pb-8 text-p1">
                    <strong>Что такое ePN</strong>

                    <p>
                        ePN - это CPA-платформа, где представлены лучшие партнерки для вебмастеров
                        от самых известных интернет-магазинов. <br />
                        Мы работаем в аффилированном маркетинге с 2013 года. Сегодня ePN -
                        крупнейшая партнерка Aliexpress в России. Мы также постоянно работаем над
                        привлечением новых магазинов, чтобы предлагать нашим вебмастерам
                        высокодоходные партнерки и белые офферы. <br />
                    </p>
                </div>

                <div className="pb-8 text-p1">
                    <strong>Что такое CPA</strong>

                    <p>
                        Cost per action (англ. - оплата за действия) или партнерская программа с
                        оплатой за действия - форма сотрудничества между продавцом и партнерами,
                        которые приводят потребителей на его товар. Такое взаимодействие стало
                        настоящим трендом в интернет-рекламе, поскольку выгодно всем его участникам.
                        Вебмастера с помощью специальных предложений привлекают посетителей и
                        получают прибыль за их покупки, рекламодатели получают теплую аудиторию на
                        свои товары.
                    </p>
                </div>

                <div className="pb-8 text-p1">
                    <strong>Как работает cpa-платформа</strong>

                    <p>
                        ePN выступает посредником между интернет-магазинами и вебмастерами. Мы
                        собираем самые выгодные и надежные офферы, обеспечиваем выполнение всех
                        условий, предоставляем вебмастерам расширенный инструментарий для
                        эффективной работы.
                    </p>
                </div>

                <div className="pb-8 text-p1">
                    <strong>Схема работы:</strong>

                    <ul className="webmaster-seo__list">
                        <li>- вебмастер выбирает оффер от официального партнера ePN</li>
                        <li>- со своих площадок направляет трафик выбранному рекламодателю</li>
                        <li>- трафик реализуется в продажи товаров (действие, action)</li>
                        <li>- ePN оплачивает действие вебмастеру</li>
                    </ul>
                </div>

                <div className="pb-8 text-p1">
                    <strong>Как заработать на партнерках</strong>

                    <p>
                        Есть множество способов заработать на партнерках. Если у вас есть
                        собственный сайт, вы можете разместить рекламу официальных партнеров ePN,
                        соответствующую тематике вашей площадки, и зарабатывать на привлеченных
                        покупателях.
                    </p>
                </div>

                <div className="pb-8 text-p1">
                    <strong>Также для привлечения трафика можно использовать:</strong>

                    <ul className="webmaster-seo__list">
                        <li>- Тематические сайты</li>
                        <li>- Баннерную и тизерную рекламу</li>
                        <li>- Группы в социальных сетях</li>
                        <li>- Таргетированная реклама в социальных сетях</li>
                        <li>- Приложения и игры в социальных сетях</li>
                        <li>- Мобильные приложения</li>
                        <li>- Cashback</li>
                        <li>- Контекстная реклама</li>
                        <li>- E-mail и SMS - рассылки</li>
                    </ul>

                    <p>Требования к категориям трафика зависят от условий конкретного оффера.</p>
                </div>

                <div className="pb-8 text-p1">
                    <strong>Работа с ePN Cashback</strong>

                    <p>Сегодня с ePN работают 700 000 вебмастеров из более чем 100 стран мира.</p>
                </div>

                <div className="pb-8 text-p1">
                    <strong>Вебмастерам ePN предлагает особые условия:</strong>

                    <ul>
                        <li>партнерки от крупнейших интернет-ритейлеров</li>
                        <li>высокая ставка и многоуровневая система начисления бонусов</li>
                        <li>стабильные и быстрые выплаты</li>
                        <li>
                            вывод денег на WMZ, ePayments, Wire Transfer, Яндекс.Деньги, карты Visa
                            и MasterCard и в криптовалюту Toncoin
                        </li>
                        <li>
                            бесплатная карта ePayments, которая позволяет совершать интернет покупки
                            без комиссий и выводить заработанные средства без сборов платежных
                            систем
                        </li>
                        <li>
                            плагин ePN, позволяющий получить партнерскую ссылку на любую открытую в
                            браузере страницу магазина, не покидая ее
                        </li>
                        <li>техподдержка на русском и английском языках </li>
                        <li>
                            реферальная программа, где каждый пользователь, который зарегистрируется
                            по вашей ссылке, становится вашим рефералом и вы получаете процент от
                            его покупок
                        </li>
                        <li>большой набор инструментов вебмастера</li>
                    </ul>
                </div>

                <div className="pb-8 text-p1">
                    <strong>Как стать вебмастером ePN:</strong>

                    <ul>
                        <li>зарегистрироваться в программе ePN</li>
                        <li>получить партнерскую ссылку</li>
                        <li>направлять трафик выбранному рекламодателю </li>
                        <li>получать прибыль за привлеченных покупателей</li>
                    </ul>

                    <p>
                        Выбирая ePN Cashback, вы получаете не только удобную площадку для работы, но
                        разнообразие выгодных партнерок от самых крупных интернет-продавцов.{' '}
                    </p>

                    <p>Регистрируйтесь и зарабатывайте с нами!</p>
                </div>
            </div>
        );
    }

    return (
        <div className="text-blueGray-800">
            <div className="pb-8 text-p1">
                <strong>What is e-Commerce Partners Network</strong>

                <p>
                    e-Commerce Partners Network is a CPA platform where the best affiliates for
                    webmasters from the most famous online stores are presented. <br />
                    We have been working in affiliate marketing since 2013. We are also constantly
                    working on attracting new stores to offer our webmasters high-yielding
                    affiliates and white offers. <br />
                </p>
            </div>

            <div className="pb-8 text-p1">
                <strong>What is CPA?</strong>

                <p>
                    Cost per action or affiliate program with payment for actions is a form of
                    cooperation between sellers and partners that lead consumers to its product.
                    This interaction has become a real trend in Internet advertising, as it is
                    beneficial for all its participants. Webmasters with special offers attract
                    visitors and make profit for their purchases, advertisers receive a warm
                    audience for their products.
                </p>
            </div>

            <div className="pb-8 text-p1">
                <strong>How does the cpa-platform work?</strong>

                <p>
                    e-Commerce Partners Network acts as an intermediary between online stores and
                    webmasters. We collect the most profitable and reliable off-offs, we ensure the
                    fulfillment of all conditions, we provide webmasters with an extensive toolkit
                    for effective work.
                </p>
            </div>

            <div className="pb-8 text-p1">
                <strong>Scheme of work:</strong>

                <ul className="webmaster-seo__list">
                    <li>
                        - The webmaster selects an offer from the official partner e-Commerce
                        Partners Network
                    </li>
                    <li>- from its sites directs traffic to the selected advertiser</li>
                    <li>- traffic is realized in the sale of goods</li>
                    <li>- e-Commerce Partners Network pays the action to the webmaster</li>
                </ul>
            </div>

            <div className="pb-8 text-p1">
                <strong>How to earn on affiliate programs</strong>

                <p>
                    There are many ways to earn on affiliate programs. If you have your own website,
                    you can advertise official partners e-Commerce Partners Network, corresponding
                    to the topic of your site, and earn on attracted customers.
                </p>
            </div>

            <div className="pb-8 text-p1">
                <strong>Also to attract traffic you can use:</strong>

                <ul className="webmaster-seo__list">
                    <li>- Thematic sites</li>
                    <li>- Banner and teaser advertising</li>
                    <li>- Groups in social networks</li>
                    <li>- Targeted advertising in social networks</li>
                    <li>- Applications and games in social networks</li>
                    <li>- Mobile applications</li>
                    <li>- Cashback</li>
                    <li>- Contextual advertising</li>
                    <li>- E-mail and SMS</li>
                </ul>

                <p>
                    Requirements for traffic categories depend on the conditions of a specific
                    offer.
                </p>
            </div>

            <div className="pb-8 text-p1">
                <strong>Work with e-Commerce Partners Network Cashback</strong>

                <p>
                    Today, e-Commerce Partners Network employs 700,000 webmasters from more than 100
                    countries.
                </p>
            </div>

            <div className="pb-8 text-p1">
                <strong>Webmasters e-Commerce Partners Network offers special conditions:</strong>

                <ul>
                    <li>- affiliates from the largest Internet retailers</li>
                    <li>- high rate and multi-level bonus system</li>
                    <li>- stable and fast payout</li>
                    <li>
                        - withdrawal of money to WMZ, ePayments, Wire Transfer, Visa and MasterCard
                        and Toncoin cryptocurrency
                    </li>
                    <li>
                        - free ePayments card, which allows you to make Internet purchases without
                        commissions and withdraw your earnings without fees of payment systems
                    </li>
                    <li>
                        - referral program where each user who registers with your link becomes your
                        referral and you get a percentage of his purchases
                    </li>
                    <li>- a large set of webmaster tools</li>
                </ul>
            </div>

            <div className="pb-8 text-p1">
                <strong>How to become a webmaster e-Commerce Partners Network:</strong>

                <ul>
                    <li>- register in the program e-Commerce Partners Network</li>
                    <li>- get affiliate link</li>
                    <li>- send traffic to the selected advertiser</li>
                    <li>- profit from attracted customers</li>
                </ul>

                <p>
                    Choosing e-Commerce Partners Network Cashback, you get not only a convenient
                    platform for work, but a variety of profitable partners from the largest
                    Internet sellers.
                </p>

                <p>Register and earn with us!</p>
            </div>
        </div>
    );
};

export default TextByLocale;

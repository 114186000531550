import clsx from 'clsx';
import styles from './AdvantageBlock.module.scss';

interface IAdvantageBlockProps {
    imgSrc: string;
    title: string;
    text: string;
    withBigIcon?: boolean;
    cardWithShortHeader?: boolean;
}

const AdvatageBlock: React.FC<IAdvantageBlockProps> = ({
    imgSrc,
    title,
    text,
    withBigIcon,
    cardWithShortHeader,
}) => {
    return (
        <div className={styles.advantageBlock}>
            <div
                className={clsx(styles.advantageBlock_img, {
                    [styles.advantageBlock_img__XL]: withBigIcon,
                })}
            >
                <img src={imgSrc} alt={text} />
            </div>
            <div className={styles.advantageBlock_text}>
                <div
                    className={clsx(styles.advantageBlock_text_title, {
                        [styles.advantageBlock_text_title__short]: cardWithShortHeader,
                    })}
                >
                    {!withBigIcon && <img src={imgSrc} alt={text} />}
                    {/* eslint-disable-next-line react/no-danger */}
                    <h4 dangerouslySetInnerHTML={{ __html: title }} />
                </div>
                <p>{text}</p>
            </div>
        </div>
    );
};

export default AdvatageBlock;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';

// Helpers
import useI18n from '@i18n';
import { generateRoute } from '@utils/router';
import auth from '@config/auth.json';

// Components
import WhiteButton from '../WhiteButton';

// Styles
import styles from './Header.module.scss';

const WebmasterHeader: React.FC = () => {
    const { t, locale } = useI18n();
    const registrationLink = generateRoute(auth.REG_URL, { locale });

    return (
        <section className={styles.Header}>
            <div className={styles.landingContainer}>
                <div className={styles.Header__wrapper}>
                    <div className={styles.Header__content}>
                        <h1 className={styles.Header__title}>
                            {t('e-Commerce Partners Network - helps you monetize your traffic')}
                        </h1>
                        <div className={styles.Header__subTitle}>
                            {t(
                                'Each webmaster can place ads from one of the official e-Commerce Partners Network partners on their platform and earn from referred customers.',
                            )}
                        </div>
                        <div>
                            <WhiteButton
                                block={false}
                                transparent={false}
                                href={registrationLink}
                                className={styles.Header__button}
                            >
                                <span>
                                    {t('Sign up')}
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        className={styles.Header__buttonIcon}
                                    />
                                </span>
                            </WhiteButton>
                        </div>
                    </div>
                    <div className={styles.Header__image} />
                </div>
                <div className={styles.Header__stats}>
                    <div className={styles.Header__statItem}>
                        <h3>770 000+</h3>
                        <p className={styles.Header__statItem__xs}>
                            {t('Webmasters work with us')}
                        </p>
                        <p className={styles.Header__statItem__sm}>{t('all webmasters')}</p>
                        <p className={styles.Header__statItem__md}>
                            {t('Webmasters all over the world work with us')}
                        </p>
                    </div>
                    <div className={styles.Header__statItem}>
                        <h3>$74.4M+</h3>
                        <p className={styles.Header__statItem__xs}>
                            {t('Paid out to our partners')}
                        </p>
                        <p className={styles.Header__statItem__sm}>{t('Paid to our')}</p>
                        <p className={styles.Header__statItem__md}>
                            {t('Paid out to our partners')}
                        </p>
                    </div>
                    <div className={styles.Header__statItem}>
                        <h3>$1.5B+</h3>
                        <p className={styles.Header__statItem__xs}>
                            {t('Generated in revenue for advertisers')}
                        </p>
                        <p className={styles.Header__statItem__sm}>{t('Generated in revenue')}</p>
                        <p className={styles.Header__statItem__md}>
                            {t('Generated in revenue for advertisers')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WebmasterHeader;
